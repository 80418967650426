import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import './style/sub-hero-domain.scss'

// import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import { goTo } from '../../helpers/go-to-helper'
import TldLogos from './../../images/tld_logos.svg'

const SubHeroDomain = ({ title, subTitle, backgroundImage }) => {
  const inputRef = useRef()

  let validateUserInput = currentInput => {
    let filteredChars = /[^(a-zA-Z0-9-.)]+/ig
    let leadingTrailingHyphensAndPeriods = /^(-|\.){1,}|(-|\.){1,}$/g
    let testForFullValidDomain = /^(www.){0,1}[(a-z0-9-)]+(\.[a-z0-9-]{2,}){1,2}$/ig

    currentInput = currentInput || ''
    let filteredInput = currentInput
      .replace(filteredChars, '')
      .replace(leadingTrailingHyphensAndPeriods, '')

    return (testForFullValidDomain.test(filteredInput) || filteredInput.indexOf('.') === -1) ? filteredInput : ''
  }

  let setWindowLocation = () => {
    let validatedInput = validateUserInput(inputRef.current.value)
    if (validatedInput.length > 0) {
      goTo(`${ process.env.GATSBY_SEARCH_DOMAIN_URL }${ validatedInput }`)
    }
  }

  let submitSearchQueryOnReturn = event => {
    if (event.which === 13 && event.key === 'Enter') {
      setWindowLocation()
    }
  }

  return (
    <section className='sub-hero-domain domain-hero'>
      <div className='background-image' style={{ backgroundImage: 'url(' + backgroundImage + ')' }} />
      <div className='container text-center'>
        <div className='row'>
          <div className='col-lg-10 col-xl-8 mx-auto'>
            <h2>{title}</h2>
            <p className='body-large'>{subTitle}</p>
            <div className='domain-search-form'>
              <input
                type='text'
                name='domain'
                placeholder='Search your domain name'
                ref={inputRef}
                autocomplete='off'
                autocorrect='off'
                spellcheck='false'
                onKeyPress={event => submitSearchQueryOnReturn(event)}
                dataElementLocation={DataElementLocations.NAV}
                dataElementLabel={'search-domain-input'}
                dataElementId={'domain-sub-hero-item-search-domain-input'} />
              <button
                type='submit'
                onClick={() => setWindowLocation()}
                dataElementLocation={DataElementLocations.NAV}
                dataElementLabel={'search-domain-button'}
                dataElementId={'domain-sub-hero-item-search-domain-button'}>Search</button>
            </div>
            <div className='domain-tld-logos'>
              <img src={TldLogos} alt='Domain Suffixes' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SubHeroDomain.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default SubHeroDomain
