import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InPageImage from '../components/in-page-image'
import SubHeroDomain from '../components/sub-hero-domain'
import TagLine from '../components/tagline'
import DiscountBanner from '../components/discount-banner'
import DiscountTerms from '../components/discount-terms'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Domain'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'product_page'}
      flowType={'domain'} />
    <DiscountBanner />
    <SubHeroDomain
      title={'Find a great domain'}
      subTitle={'For your website, email, or awesome idea'}
      backgroundImage={'https://images.unsplash.com/photo-1527234639945-70d78416bd7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2638&q=80'} />
    <section>
      <div className='container section-padding text-section'>
        <TagLine title={'Get more than a domain name with WebsiteBuilder'} tagSize={'h2'} />
        <div className='row'>
          <div className='col-md-6'>
            <h3>We’ll protect you from spam</h3>
            <p className='black-60'>When a domain is registered, personal information can be exposed to spammers. That’s why we include domain privacy protection at no additional cost.*</p>
            <p className='black-60'>All of our domains also include a wide array of security features to keep your site secure and operational.</p>
          </div>
          <div className='col-md-6 image' style={{ marginTop: 0 }}><InPageImage fileName='domain-1.jpg' alt='protection' /></div>
        </div>
        <div className='row'>
          <div className='col-md-6 image'><InPageImage fileName='domain-2.jpg' alt='brand' /></div>
          <div className='col-md-6 swap'>
            <h3>Build a brand, create an impactful image</h3>
            <p className='black-60'>A domain name is one of the first steps to establishing your brand. Secure a consistent brand image with a domain name that matches your business.</p>
            <p className='black-60'>Create customer confidence by linking your domain and your email address to look even more professional.</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <h3>Get a free domain name with our annual plans</h3>
            <p className='black-60'>Save money and receive a free domain with any of our annual website builder subscription plans.</p>
            <p className='black-60'>Simply purchase a one or two year plan and we’ll cover the price of the domain for the first year.*</p>
            <Button
              href='/pricing'
              dataElementLocation={DataElementLocations.BODY}
              dataElementLabel={'explore-plans'}
              dataElementId={'domain-body-item-explore-plans'}
              color='primary'
              variant='contained'
              className='button-xl button-blue'
              size='large'>
              Explore plans
            </Button>
          </div>
          <div className='col-md-6 image' style={{ marginBottom: 0 }}><InPageImage fileName='domain-3.jpg' alt='working at desk' /></div>
        </div>
      </div>
      <DiscountTerms />
    </section>
  </Layout>
)

export default IndexPage
