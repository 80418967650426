import React from 'react'
import PropTypes from 'prop-types'

import './style/tagline.scss'

const TagLine = ({ title, tagSize }) => {
  const HTag = tagSize ? `${ tagSize }` : `h4`

  return (
    <div className="row tagline">
      <div className="col-12">
        <div className="line"></div>
        <HTag>{title}</HTag>
        <div className="line"></div>
      </div>
    </div>
  )
}
TagLine.propTypes = {
  title: PropTypes.string
}

export default TagLine
